import { inputIntValue } from '../tools';
import { push_GA_checkout_event, storeGAid } from './tools';
import { createGooglePayPaymentRequest } from './providers/stripe';

export function intIncreasePledgeToMinValue($form) {
  // For update pledge popups when pledge is lower then min for given channel
  $(document).on('click', '.js-change-pledge', function() {
    const new_pledge = parseInt($(this).data('value'));
    $form
      .find('#id_pledge')
      .val(new_pledge)
      .trigger('change');

    // select matching pledge preset if present
    const $pledge_preset = $form.find(
      `.js-pledge-preset[value="${new_pledge}"]`,
    );
    if ($pledge_preset.length) {
      $pledge_preset.click();
    }
    pomagamy.close_popup();
  });
}

export function initTosCheckEvent($eventForm) {
  $('#id_accept_pomagam_tos').on('click', function() {
    push_GA_checkout_event($eventForm, 3, undefined, 'Accept Terms');
  });
}

export function initPaymentChannelsEvents($form) {
  const $userDataFields = $form.find('.js-user-data-wrapper');
  const $supportCopyApplePay = $form.find('.js-hide-support-copy-apple-pay');
  const $supportCopyOtherChannels = $form.find('.js-hide-support-copy');

  $form.find('.bank-option > label').on('click', function(e) {
    // Due to some internal shenanigans,
    // `.bank-option` click event triggers twice
    // with different targets (input and image).
    // This is a workaround to prevent double triggering of the event.
    // Additionally, remember to DO NOT directly bind on .bank-option element
    // because it's bigger than its visual representation.
    if (e.target.nodeName === 'INPUT') {
      return;
    }

    var $this = $(this);
    $this.find('input').triggerHandler('focus.remove-errors');

    push_GA_checkout_event(
      $form,
      2,
      $this.find('input').attr('value'),
      'Select payment method',
    );
    const isApplePaySelected = $this.find('input').val() === $form.attr('data-zen-apple-pay-channel-id');

    // Hide user data fields for Apple Pay
    $userDataFields.toggleClass('d-none', isApplePaySelected);
    // Show proper copy for Apple Pay / Other channels
    $supportCopyApplePay.toggleClass('d-none', !isApplePaySelected);
    $supportCopyOtherChannels.toggleClass('d-none', isApplePaySelected);
  });
}

export function initShowAllBanksEvent($form) {
  let $btn = $form.find('#show-all-banks');
  $btn.on('click', function() {
    $form.find('.bank-option').removeClass('hidden');
    $btn.remove();
  });
}

export function initOnlineSubmitEvent($form) {
  const PAYPAL = 'PayPal';
  const GOOGLE_PAY = 'Google Pay';
  const APPLE_PAY = 'Apple Pay';
  const CREDIT_CARD = gettext('supwiz-channel-credit-card');
  const $ga_client_id = $form.find('#id_ga_client_id');
  const $pledge = $form.find('#id_pledge');
  const $donation = $form.find('#id_donation');
  $form.on('click', '#submit-btn', function() {
    storeGAid($ga_client_id);

    const channel_name = $form
      .find('input[name=payment_channel]:checked')
      .next('img')
      .attr('alt');

    const provided_by_PayPal =
      channel_name === PAYPAL || (channel_name === CREDIT_CARD && $form.hasClass('js-allow-paypal-cc'));
    const provided_by_Stripe =
      channel_name === GOOGLE_PAY || (channel_name === CREDIT_CARD && $form.hasClass('js-allow-stripe-cc'));
    const provided_by_Zen_cc =
      channel_name === CREDIT_CARD && $form.hasClass('js-allow-zen-cc');
    const provided_by_Zen_ApplePay =
      channel_name === APPLE_PAY && $form.hasClass('js-allow-zen-apple-pay');

    const pledge = inputIntValue($pledge);
    const donation = inputIntValue($donation);
    // This value is used only for the UI elements (GooglePay flow).
    // The real (billing) tx value is passed to Stripe by the backend api call,
    // creating a PaymentIntent.
    const total_cost = pledge + donation;

    function pledgeTooLow(popup_selector) {
      const $elem = $(`${popup_selector} .js-change-pledge`);
      return pledge < parseInt($elem.data('value'));
    }

    // Check if the pledge is too low for the selected payment channel
    if (provided_by_PayPal && pledgeTooLow('#paypal-popup')) {
      pomagamy.show_popup('#paypal-popup');
    } else if (provided_by_Stripe && pledgeTooLow('#stripe-popup')) {
      // Stripe popup is used for both credit card and Google Pay (same minimum pledge).
      pomagamy.show_popup('#stripe-popup');
    } else if (provided_by_Zen_cc && pledgeTooLow('#zen-popup-cc')) {
      pomagamy.show_popup('#zen-popup-cc');
      if ($.fn.stopPulseLoading) {
        $.fn.stopPulseLoading();
      }
    } else if (provided_by_Zen_ApplePay && pledgeTooLow('#zen-popup-apple-pay')) {
      pomagamy.show_popup('#zen-popup-apple-pay');
      if ($.fn.stopPulseLoading) {
        $.fn.stopPulseLoading();
      }
    } else if (channel_name === GOOGLE_PAY) {
      createGooglePayPaymentRequest(total_cost);
      $form.submit();
    } else {
      $form.submit();
    }
  });
}

export function initPledgeEvents($form, $pledge, slider, summary_update_function) {
  const $pledge_helper = $form.find('#id_pledge_helper');
  const $slider = $(slider);
  const noPledgeSliderClass = 'desaturated';

  function updateSliderSaturation() {
    // Adds or removes desaturation class from slider depending on whether the pledge is set
    $slider.toggleClass(noPledgeSliderClass, !($pledge.val() > 0));
  }

  // Pledge helper is placed inside `pledge-error-wrapper` and it's errors are connected to `pledge`.
  // This means that pledge helper click should also reset pledge errors.
  $pledge_helper.on('click touch', function(e) {
    $pledge.triggerHandler('focus.remove-errors');
  });

  $pledge_helper.on('keyup change', function(e) {
    // update pledge value when helper input changes
    $pledge.val(e.target.value);
    $pledge.trigger('change');
  });

  // desaturate slider when pledge is not set initially (also handles support-restore mode)
  updateSliderSaturation();

  $pledge.on('change', function() {
    // fake slider update to rerender its label
    if ($slider.is(':visible')) {
      slider.noUiSlider.set(slider.noUiSlider.get());
    }
    updateSliderSaturation();
    summary_update_function();
  });

  $pledge_helper.siblings('i.currency').on('click', function() {
    $pledge_helper.focus();
  });

  $pledge_helper.on('keydown', function() {
    // remove selection from pledge preset if user types anything in pledge input field
    $form.find('div.btn.btn-outline.selected').removeClass('selected');
  });
}

export function initCustomDonationHelperEvents(
  $form,
  summary_update_function,
  on_blur_function,
) {
  $form
    .find('#id_donation_helper')
    .on('keyup', function() {
      summary_update_function();
    })
    .on('blur', function() {
      // Triggers when focus is lost on the custom donation input
      on_blur_function($(this).val() === '0');
    });
}
