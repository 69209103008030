import store from 'store';

function removeHowToPay() {
  $('#how-to-pay-popup').remove();
  $(document).off('hidden.bs.modal', removeHowToPay);
}

function initCancelButton() {
  $('.js-payment-canceled').on('click', function() {
    $(document).on('hidden.bs.modal', removeHowToPay);

    store.set('perma', false);
    pomagamy.close_popup();
    return false;
  });
}

function initGoToPaymentButton($popups) {
  // Handles redirect to a payment provider.
  // Opening provider's page in a new window triggers changes in popup,
  // resulting in different content/buttons.
  var $go_to_payment = $popups.find('#js-go-to-payment');

  function updatePopupContent() {
    $popups.find('.modal.show .provider-info').toggleClass('hidden');
    $popups
      .find('.modal.show .popup-footer .provider-info')
      .toggleClass('d-flex button-group w-100 justify-content-between');
    $popups.find('.modal.show #js-cancel-pre-payment').hide();
    $go_to_payment.hide();
  }

  $go_to_payment.on('click', function() {
    const in_new_window = $go_to_payment.attr('target') === '_blank';
    if (in_new_window) {
      store.set('perma', true);
      updatePopupContent();
      window.open($go_to_payment.attr('href'), '_blank');
      return false;
    } else {
      store.set('perma', false);
    }
  });
}

export function HowToPayOnLoad($popups) {
  initCancelButton();

  // Temporarily disable modal.hide feature.
  // TODO - fix perma for stripe payments
  store.set('perma', true);

  initGoToPaymentButton($popups);
}
